import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../../app/insights_admin/components/base/ViewComponent"

interface MarkdownConverterProps extends ViewComponentProps {
  linksInNewWindow?: boolean
  imagesToFullWidth?: boolean
  imagesToHalfWidth?: boolean
  linksToAppDirectly?: boolean
  history?
  className?: string
}

export default class MarkdownConverter extends ViewComponent<MarkdownConverterProps, {}> {
  get componentName(): string[] {
    return ["admin_panel", "ui", "MarkdownConverter"]
  }
  private contentDiv: HTMLDivElement

  constructor(props) {
    super(props)
    setTimeout(() => {
      this.update()
    }, 0)
  }

  componentDidUpdate(): void {
    this.update()
  }

  update() {
    this.updateLinksToNewWindow()
    this.updateImagesToFullWidth()
    this.updateImagesToHalfWidth()
    this.updateLinksToAppDirectly()
  }

  render(): JSX.Element {
    return (
      <div
        className={this.props.className}
        ref={div => {
          this.contentDiv = div
        }}
      >
        {this.props.children}
      </div>
    )
  }

  private updateLinksToAppDirectly() {
    const { history } = this.props

    if (!this.props.linksToAppDirectly) return

    // Make all links open in a new browser window
    const anchors = this.contentDiv.getElementsByTagName("a")

    const location = window.location
    const baseUrl = `${location.protocol}//${location.host}`

    for (const a of anchors as any) {
      if (a.href.startsWith(baseUrl)) {
        const page = a.href.replace(baseUrl, "")
        a.removeEventListener("click", event => {
          event.preventDefault()
          history.push(page)
        })

        a.addEventListener("click", event => {
          event.preventDefault()
          history.push(page)
        })
      }
    }
  }

  private updateLinksToNewWindow() {
    if (!this.props.linksInNewWindow) return

    // Make all links open in a new browser window
    const anchors = this.contentDiv.getElementsByTagName("a")

    for (const a of anchors as any) {
      a.target = "_blank"
    }
  }

  private updateImagesToFullWidth() {
    if (!this.props.imagesToFullWidth) return

    // update all images to be of full width
    const images = this.contentDiv.getElementsByTagName("img")

    for (const img of images as any) {
      img.style.width = "100%"
    }
  }

  private updateImagesToHalfWidth() {
    if (!this.props.imagesToHalfWidth) return

    // update all images to be of full width
    const images = this.contentDiv.getElementsByTagName("img")

    for (const img of images as any) {
      img.style.width = "50%"
    }
  }
}
